<template>
  <router-view> </router-view>
</template>

<script setup lang="ts"></script>

<style lang="scss">
@import '@/assets/normalize.css';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600&family=Unbounded:wght@500&display=swap');

#app {
  display: flex;
  min-height: 100dvh;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #211f1f;
}
</style>
