import { createRouter, createWebHistory } from 'vue-router'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: () => import('../views/AppMain.vue'),
    },
    {
      path: '/feedback/310c7f2b-ec7d-4140-9ef6-c868e3bcc3b9',
      component: () => import('../views/AppMain.vue'),
    },
    {
      path: '/feedback/:id',
      component: () => import('../views/AppWrapper.vue'),
      props: true,
    },
  ],
})
